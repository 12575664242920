import { Box, Typography, Container } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import HomeIcon from "@mui/icons-material/Home"
import { useNavigate } from "react-router-dom" // Import useNavigate
import helpline from "../public/helpline.webp"
import Mobile from "./Mobile"

const CustomerService = () => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate("/account")
  }

  return (
    <Mobile>
      <Container
        disableGutters
        maxWidth="xs"
        sx={{
          bgcolor: "#f5f5f5",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            bgcolor: "#0F6518",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <ChevronLeftIcon
            sx={{ fontSize: 30, cursor: "pointer" }}
            onClick={handleBackClick}
          />

          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            Customer Service
          </Typography>
          <HomeIcon sx={{ fontSize: 30 }} />
        </Box>

        <Box>
          <Box
            component="img"
            src={helpline}
            alt="About us illustration"
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>

        {/* <List sx={{ bgcolor: "white", m: 2, borderRadius: 2 }}>
          <ListItem
            button // Add button prop to make it clickable
            onClick={() => navigate("/support")} // Navigate to /support on click
            secondaryAction={
              <IconButton edge="end" aria-label="go to">
                <ChevronRightIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <Box
                sx={{
                  bgcolor: "red",
                  borderRadius: "50%",
                  width: 35,
                  height: 35,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhoneIcon sx={{ color: "white" }} />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary="LiveChat"
              primaryTypographyProps={{ fontWeight: "medium" }}
            />
          </ListItem>
        </List> */}

        <Typography
          variant="h6"
          color="textPrimary" // Adjust for contrast against the white background
          gutterBottom
        >
          For customer service
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Phone: (123) 456-7890
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Email: support@example.com
        </Typography>
      </Container>
    </Mobile>
  )
}

export default CustomerService
